import { h, Component } from "preact";
import Match, { Link } from "preact-router/match";

export interface HeaderState {
  navbarOpen: boolean;
  isLoggedIn: boolean;
}
class Header extends Component<any, HeaderState> {
  constructor(props: {} | undefined) {
    super(props);
    this.state = {
      navbarOpen: false,
      isLoggedIn: false
    };
  }

  toggleNavbar(): void {
    this.setState({
      ...this.state,
      navbarOpen: !this.state.navbarOpen
    });
  }

  resetNavbar(): void {
    this.setState({
      ...this.state,
      navbarOpen: false
    });
  }

  componentDidMount() {
    const isLoggedIn = (document.cookie || "").includes(
      "rb.dashboard.loggedIn=true"
    );
    this.setState({
      ...this.state,
      isLoggedIn
    });
  }
  isLoggedInDashboard(): boolean {
    return;
  }

  render(_: any, { navbarOpen }: HeaderState): JSX.Element {
    return (
      <header class="main-header alternate">
        <div class="main-box">
          <div class="auto-container">
            <div class="outer-container clearfix">
              <div class="logo-box">
                <div class="logo">
                  <Link href="/">
                    <img src="/assets/logo.svg" alt="logo remove bounce" />
                  </Link>
                </div>
              </div>

              <div class="nav-outer clearfix">
                <nav class="main-menu">
                  <div class="navbar-header">
                    <button
                      onClick={() => this.toggleNavbar()}
                      type="button"
                      class="navbar-toggle"
                      data-toggle="collapse"
                      data-target=".navbar-collapse"
                    >
                      <span class="icon-bar"></span>
                      <span class="icon-bar"></span>
                      <span class="icon-bar"></span>
                    </button>
                  </div>

                  <div
                    className={
                      navbarOpen
                        ? "navbar-collapse collapse scroll-nav clearfix in"
                        : "navbar-collapse collapse scroll-nav clearfix"
                    }
                  >
                    <ul class="navigation clearfix">
                      <Match path="/">
                        {({ path }: { path: string }) => (
                          <li className={path === "/" ? "current" : ""}>
                            <Link onClick={() => this.resetNavbar()} href="/">
                              Home
                            </Link>
                          </li>
                        )}
                      </Match>
                      <Match path="/email-list-cleaning-services">
                        {({ path }: { path: string }) => (
                          <li
                            className={
                              path === "/email-list-cleaning-services" ? "current" : ""
                            }
                          >
                            <Link
                              onClick={() => this.resetNavbar()}
                              href="/email-list-cleaning-services"
                            >
                              Services
                            </Link>
                          </li>
                        )}
                      </Match>
                      <Match path="/pricing">
                        {({ path }: { path: string }) => (
                          <li className={path === "/pricing" ? "current" : ""}>
                            <Link
                              onClick={() => this.resetNavbar()}
                              href="/pricing"
                            >
                              Pricing
                            </Link>
                          </li>
                        )}
                      </Match>
                      <Match path="/email-cleaning-api">
                        {({ path }: { path: string }) => (
                          <li className={path === "/email-cleaning-api" ? "current" : ""}>
                            <Link
                              onClick={() => this.resetNavbar()}
                              href="/email-cleaning-api"
                            >
                              API
                            </Link>
                          </li>
                        )}
                      </Match>
                      <Match path="/faqs">
                        {({ path }: { path: string }) => (
                          <li className={path === "/faqs" ? "current" : ""}>
                            <Link
                              onClick={() => this.resetNavbar()}
                              href="/faqs"
                            >
                              FAQ
                            </Link>
                          </li>
                        )}
                      </Match>
                      <Match path="/blog">
                        {({ path }: { path: string }) => (
                          <li className={path === "/blog" ? "current" : ""}>
                            <Link
                              onClick={() => this.resetNavbar()}
                              href="/blog"
                            >
                              Blog
                            </Link>
                          </li>
                        )}
                      </Match>
                      <Match path="/contact">
                        {({ path }: { path: string }) => (
                          <li className={path === "/contact" ? "current" : ""}>
                            <Link
                              onClick={() => this.resetNavbar()}
                              href="/contact"
                            >
                              Contact
                            </Link>
                          </li>
                        )}
                      </Match>
                      <Match path="/login">
                        {({ path }: { path: string }) => (
                          <li>
                            <Link
                              onClick={() => this.resetNavbar()}
                              href={this.state.isLoggedIn
                                ? "https://dashboard.removebounce.com"
                                : "https://dashboard.removebounce.com/auth/register"}
                              class="theme-btn btn-style-one login-button"
                              target="_blank"
                            >
                              {this.state.isLoggedIn
                                ? "Go to Dashboard"
                                : "Get Started"}
                            </Link>
                          </li>
                        )}
                      </Match>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
